.App {
    text-align: center;
    color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
    font-size: 5em;
    margin: 0;
    text-shadow: 2px 2px 3px rgb(0 0 0 / 60%);
}

h2 {
    margin: 1em;
}

hr {
    width: 400px;
    border: 1;
}

button {
    padding: 12px 18px;
    font-size: 14px;
    line-height: 1.33;
    border-radius: 6px;
    margin: 15px;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    text-decoration: none;
    color: #333;
}

button > span {
    margin-left: 5px;
}

button:enabled {
    cursor: pointer;
}

button:hover:enabled,
button:active:enabled {
    background-color: #adadad;
}

.buttons,
.versions {
    margin-bottom: 30px;
}

p {
    margin: 0.3em 0;
}

.loader {
    margin-top: 40px;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #74c7ff;
    border-bottom: 5px solid #74c7ff;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 0.5s linear infinite; /* Safari */
    animation: spin 0.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
